export const environment = {
  production: false,
  ENV: 'DEV',
  APP_Ocp_Apim_Subscription_Key: '60696592291e4771ab1b30cc49d57094',
  LUMINA_APP_BASE_URL: 'https://lumina-dev.dnv.com',
  LUMINA_APP_CLIENT_ID: '67c18d0e-3c67-4f00-9dc8-566c7794f8f5',
  LUMINA_APP_LOGOUT_URL: 'https://developer.veracity.com/auth/logout',
  APP_BASE_URL: 'https://testapi.dnv.com/BALUMINA-API-DEV/v0/',
  SERVICE_ID:'19e8a01c-af2d-424d-a1c8-5bf522306cac',
  appInsights: {
    instrumentationKey: '174c9524-e4ed-4fd6-9ace-cc365264b836',
  },
};
